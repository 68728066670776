<template>
  <div class="nav-search-results">
    <TransitionResults>
      <ResultsTitle v-if="getMappedHits.length > 0">
        {{
          UI_ELEMENT("search_results_subtitle_collections") +
            ` (${getMappedHits.length})`
        }}
      </ResultsTitle>
    </TransitionResults>
    <TransitionResults group>
      <NavSearchResultsItem
        v-for="hit in getMappedHits"
        :key="hit.id"
        :content="hit"
        @clicked="() => handleResultClick(hit)"
      />
    </TransitionResults>
  </div>
</template>

<script>
import { createWidgetMixin } from "vue-instantsearch"
import { connectHits } from "instantsearch.js/es/connectors"

import NavSearchResultsMixin from "./NavSearchResultsMixin"

import NavSearchResultsItem from "./NavSearchResultsItem.vue"

export default {
  mixins: [
    NavSearchResultsMixin,
    createWidgetMixin({ connector: connectHits })
  ],

  components: {
    NavSearchResultsItem
  },

  data: () => ({
    maxResults: 5 // The number of results to return for this view - used by NavSearchResultsMixin
  }),

  computed: {
    getMappedHits() {
      // TODO: Obviously this needs to be updated to make more sense and not have placeholder field names...
      return this.getHits.map(hit => ({
        ...hit,
        sys: {
          id: hit.id
        },
        __typename: "CollectionPage"
      }))
    }
  }
}
</script>

<style lang="scss">
@import "./NavSearchResults.scss";
</style>
